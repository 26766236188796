.shop-now .shop-now-banner .overflow-blue-div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: #00afefb8;
}

a.bottle-img-outer {
    border: none !important;
    outline: none !important;
    background: transparent !important;
}

/* a.bottle-img-outer:hover {
    box-shadow: 0px 0px 6px 4px aliceblue;
} */
@media screen and (max-width: 768px) {
    .shop-now .bottle-card {
        zoom: 90%;
    }
}