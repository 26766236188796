/* AdvertiseBanner.css */
.advertise-banner-wrapper {
  background-color: #00afef;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 50px;
}

.banner-title {
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
}

.banner-description {
  line-height: 33px;
  color: #ffffff;
}

@media screen and (max-width: 550px) {
  .mini-bilboard .bottles .bottle-card {
    width: 45%;
    zoom: 80%;
  }

  .mini-bilboard .bottles .bottle-card .bottle-img-200-ml {
    height: 960;
  }
}
