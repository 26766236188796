.name-text {
  font-size: 28px;
}

.our-water .banner {
  min-height: 660px;
  background-image: url("../../assets/images/whats_your_true_nature_mountain.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.our-water .banner img.banner-bottles {
  width: 560px;
  padding-top: 50px;
}

.our-water .banner::after {
  background-image: url("../../assets/svg/ice-1.svg");
  right: 55px;
  top: 0px;
  max-width: 960px;
  opacity: 0.8;
}

.our-water .banner::before {
  background-image: url("../../assets/svg/drop-logo.svg");
  left: -135px;
  top: 30px;
  bottom: 0;
  opacity: 0.46;
}

.our-water .banner h1 {
  line-height: 4.5rem;
  font-size: 75px;
}

.our-water .banner p {
  font-size: 26px;
  line-height: 42px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 600;
}

.our-water .text-div {
  height: 437px;
  background-color: #d5eaf9;
}

.our-water .text-div-outer {
  max-width: 914px;
  margin: 0 auto;
}

.whats-in-water h2.uppercase,
.contaminants h2.uppercase,
.our-water .text-div-outer h2 {
  font-size: 40px;
  line-height: 48px;
  text-transform: uppercase;
  color: #1f2122;
  font-weight: 700;
}

.our-water .text-div-outer p {
  font-size: larger;
  font-weight: 400;
  line-height: normal;
}

.our-water .text-div-outer button {
  width: 200px;
  height: 50px;
  border-radius: 6px;
  background-color: #000;
}

.our-water .text-div-outer button span {
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 47px;
  color: #fff;
  font-weight: 500;
}

.contaminants-card {
  width: 345px;
  height: 295px;
  min-height: auto;
  border-radius: 10px;
  background-color: #edf7fd;
}

.contaminants-card .id-name-chemical {
  line-height: normal;
}

.contaminants-card .id-name-chemical .name {
  line-height: 4.5rem;
  color: #dbe4eb;
}

.contaminants-card .number {
  font-size: 80px;
  line-height: 48px;
  text-transform: uppercase;
  color: #1f497f;
  font-weight: 300;
  text-align: center;
}

.contaminant-table {
  background-color: #d5e9f9;
  padding: 80px 0;
}

.contaminant-table .images span {
  width: 230px;
  height: auto;
}

.contaminant-table .images span img {
  width: 100%;
  height: auto;
}

.tables-div table {
  width: 85%;
}

.tables-div table tr th {
  text-align: start;
}

.tables-div table tr td,
.tables-div table tr th {
  padding: 6px !important;
  font-weight: 400;
}

.tables-div table tr {
  text-transform: uppercase;
}

.tables-div table tr th {
  font-size: 18px;
  line-height: 35px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 700;
  background-color: #000000;
}

.tables-div table tr td.tick {
  width: 260px;
  text-align: center;
  font-size: x-large;
}

.tables-div table tr:nth-child(even) {
  background-color: white;
}

.tables-div table tr:nth-child(odd) {
  background-color: #c1d4e385;
}

.whats-in-water {
  background-color: #d5e9f9;
}

.whats-in-water .table-div {
  overflow: hidden;
  border-radius: 6px;
}

.whats-in-water table tr.tr-head {
  background-color: #00afef !important;
}

.whats-in-water table tr th,
.whats-in-water table tr td {
  padding: 8px 12px;
}

.whats-in-water table tr:nth-child(even) {
  background-color: white;
}

.whats-in-water table tr:nth-child(odd) {
  background-color: #e1effa;
}

.whats-in-water .absolute img {
  max-height: -webkit-fill-available;
  margin: 0 auto;
}

@media screen and (max-width: 1440px) {
  .our-water .banner::after {
    right: 10px;
    top: 80px;
    max-width: 780px;
  }

  .whats-in-water .absolute img {
    max-height: max-content;
  }
}

@media screen and (max-width: 1024px) {
  .tables-div table tr td.tick {
    width: 180px;
  }

  .contaminant-table .container .heading-div .images {
    zoom: 70%;
  }

  .our-water .banner h1 {
    line-height: 4.5rem;
    font-size: 60px;
  }

  .our-water .banner p {
    font-size: 26px;
    line-height: 35px;
  }
}

@media screen and (max-width: 768px) {
  .our-water .banner {
    min-height: 430px;
  }

  .banner div.container.relative div.txt-div {
    zoom: 70%;
  }

  .contaminant-table .container .heading-div .images {
    zoom: 55%;
  }

  .whats-in-water h2.uppercase,
  .contaminants h2.uppercase,
  .our-water .text-div-outer h2 {
    font-size: 30px;
  }
}

@media screen and (max-width: 550px) {
  .our-water .banner {
    min-height: 640px;
  }

  .our-water .banner p {
    font-size: 28px;
  }

  .our-water .banner img.banner-bottles {
    width: 550px;
    margin-top: -20px;
  }

  .our-water .banner h1 {
    margin-bottom: 20px;
    line-height: 3.5rem;
    font-size: 48px;
  }

  .whats-in-water h2.uppercase,
  .contaminants h2.uppercase,
  .our-water .text-div-outer h2 {
    font-size: 24px;
    line-height: normal;
    padding: 0 28px;
  }

  .our-water .text-div {
    height: 340px;
  }

  .our-water .text-div-outer {
    padding: 0 10px;
    width: 95%;
    gap: 0.8rem;
  }

  .our-water .text-div-outer p {
    font-size: 14px;
    font-weight: 500;
  }

  .contaminants-card {
    width: 45%;
    height: auto;
  }

  div.text-div div.btn-div {
    zoom: 70%;
  }

  .contaminant-table .container .heading-div {
    zoom: 100%;
  }

  .contaminants .contaminants-flex-container .container.mx-auto {
    zoom: 80%;
    row-gap: 20px;
  }

  .tables-div table {
    zoom: 80%;
  }

  .whats-in-water h2.uppercase {
    padding: 0;
  }

  .whats-in-water table {
    zoom: 80%;
  }
}
@media (max-width: 425px) {
  .name-text {
    font-size: 20px;
  }
}
