.map-us .map-banner, .search-filter {
    background-color: #00afef;
}

.map-us .map-search {
    display: none;
    background-color: #fff;
    height: 10rem;
}

#map-address-outer {
    height: 690px;
}

#map-address-outer p iframe {
    width: 100%;
    height: 690px;
}

.desktop-device {
    display: block;
}

.mobile-device {
    display: none;
}

.image-icon {
    width: 40px;
    height: 40px;
}
@media screen and (max-width: 768px) {
    .desktop-device {
        display: none;
    }
    
    .mobile-device {
        display: block;

    }
    .map-us .map-search {
        display: flex;
    }

    .search-filter {
        display: inline-block;
        margin: 1.5rem;
        padding: 1.25rem;
        border-radius: 0.75rem;
    }
    #search-dropdown {
        border: 1px solid black;
        padding: 0.875rem;
        border:1px solid #00afef;
        border-top-left-radius: 0.75rem;
        border-bottom-left-radius: 0.75rem;

        
    }
    .search-section{
        width: 97%;
    }

    .search-submit{
        background-color: #00afef;
        padding: 1.25rem;
        border-radius: 1rem;
        margin-left: -1rem;
    }
    .filters-text-right{
        width: 100%;
    }
    #map-address-outer {
        height: 600px;
    }
    
    #map-address-outer p iframe {
        height: 600px;
    }
}

@media screen and (max-width: 450px) {
    .map-us .map-search {
        height: 7rem;
    }
    .filters-text{
        width: 25%;
        margin-right: 0.5rem;
    }
    .filters-text-right{
        width: 73%;
    }

    .mobile-device {
        font-size: 1.875rem;
    }

    .search-filter {
        display: inline-block;
        margin: 1.15rem;
        padding: 1.15rem;
        border-radius: 0.75rem;
    }
    #search-dropdown {
        border: 1px solid black;
        padding: 0.875rem;
        border:1px solid #00afef;
        border-top-left-radius: 0.75rem;
        border-bottom-left-radius: 0.75rem;
        font-size: 1.15rem;

        
    }
    .search-section{
        margin-right: 2rem;
    }

    .search-submit{
        background-color: #00afef;
        padding: 0.875rem !important;
        border-radius: 1rem;
        margin-left: -1rem;
    }

    #map-address-outer {
        height: 530px;
    }
    
    #map-address-outer p iframe {
        height: 530px;
    }
    .image-icon {
        width: 35px;
        height: 35px;
    }
}

@media screen and (max-width: 376px) {

    .map-us .map-search {
        height: 5rem;
    }
    .filters-text{
        width: 28%;
        margin-right: 1rem;
    }
    .filters-text-right{
        width: 62%;
    }
    .mobile-device {
        font-size: 1.25rem;
    }
    .search-filter {
        display: inline-block;
        margin: 1.25rem;
        padding: 1rem;
        border-radius: 0.75rem;
        font-size: 0.875rem;
    }
    #search-dropdown {
        border: 1px solid black;
        padding: 0.875rem;
        border:1px solid #00afef;
        border-top-left-radius: 0.75rem;
        border-bottom-left-radius: 0.75rem;
        font-size: 1rem;

        
    }
    .search-section{
        margin-right: 0.875rem;
    }

    .search-submit{
        background-color: #00afef;
        padding: 0.75rem !important;
        border-radius: 1rem;
        margin-left: -2rem;
    }

    #map-address-outer {
        height: 500px;
    }
    
    #map-address-outer p iframe {
        height: 500px;
    }
}

