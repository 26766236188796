.terms-and-condition .contact-banner {
    background-color: #00afef;
}

.terms-and-condition .container {
    max-width: 1102px;
    width: 80%;
    border: .1mm solid #00afef;
    border-radius: 8px;
}

.terms-and-condition .container p,
.terms-and-condition .container span.heading {
    font-size: 18px;
    line-height: 35px;
    color: #000000;
    font-weight: 300;
}

.terms-and-condition .container span.heading {
    color: #00afef;
    line-height: 25px;
}

@media screen and (max-width: 768px) {
    .terms-and-condition .container {
        width: 90%;
        text-align: justify;
    }

    .terms-and-condition .container p {
        line-height: 28px;
    }
}