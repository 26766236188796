.advertise .advertise-banner {
    background-color: #00afef;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
}

.advertise .advertise-banner .uppercase {
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
}

.advertise-description {
    line-height: 33px;
    color: #ffffff;
}

.mini-bilboard .bilboard-promos {
    background-color: #00afef;
    /* background-image: url('../../assets/images/wave-top.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top; */
}

@media screen and (max-width: 550px) {
    .mini-bilboard .bottles .bottle-card {
        width: 45%;
        zoom: 80%;
    }

    .mini-bilboard .bottles .bottle-card .bottle-img-200-ml {
        height: 960;
    }
}