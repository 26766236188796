.star svg,
.star-half svg {
  width: 24px;
}

/* .product-info button {
  background-color: #00afef;
  width: 100%;
  padding: 15px 20px;
  border-radius: 6px;
  transition: background-color 0.3s ease;
} */

.add-to-cart-button {
  background-color: #00afef;
  width: 100%;
  padding: 15px 20px;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  height: 55px;
  border: 0px !important;
}
.product-info button span {
  font-size: 18px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 600;
}

.price-view {
  font-size: 18px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 600;
  background-color: #00afef;
  width: 50%;
  padding: 15px 20px;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

/* .product-form form input {
  opacity: 0.8;
  width: 100%;
  height: 55px;
  border-radius: 6px;
  padding: 12px;
  background-color: #ffffff;
  border: 1px solid #000000;
} */

.input-field {
  opacity: 0.8;
  width: 100%;
  height: 55px;
  border-radius: 6px;
  padding: 12px;
  background-color: #ffffff;
  border: 1px solid #000000;
}

.product-info .add-to-cart-btn {
  background-color: #00afef;
  border: none;
}

.product-info .add-to-cart-btn:hover {
  background-color: #0095d9;
}

.product-info .add-to-cart-btn span {
  color: white;
}

.product-description-details .product-detail,
.product-description-details .product-desc {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
}

.item-detail .bottles {
  background-color: #00afef;
}

.item-detail .bottles .container {
  row-gap: 15px;
}

.item-detail .bottles p {
  color: white;
}

.item-detail .bottles .bottle-card span.img {
  padding: 14px 10px;
  background-color: white;
  border-radius: 8px;
}

.slick-arrow {
  z-index: 100;
  border-radius: 6px;
  padding: 2px 4px;
  box-sizing: content-box;
}

.slick-arrow.slick-prev {
  left: 25px;
}

.slick-arrow.slick-next {
  right: 25px;
}

.slick-arrow::before {
  display: none;
}

.slick-arrow:hover {
  background-color: rgba(0, 175, 239, 0.1);
}

.slick-dots li button:before {
  border: 2.5px solid #0480ae;
  border-radius: 50%;
  content: "";
  opacity: 0.65;
  height: 12px;
  width: 12px;
}

.slick-dots li.slick-active button:before {
  background-color: #096c91;
}

.time {
  position: relative;
}

.custom-placeholder {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  pointer-events: none;
  font-family: sans-serif;
}

input[type="time"] {
  padding-left: 50px;
  color: transparent;
}

input[type="time"]::-webkit-clear-button,
input[type="time"]::-webkit-inner-spin-button {
  display: none;
}

input[type="time"].has-value {
  color: initial;
}

/* Additional responsive styles */
@media (max-width: 768px) {
  .product-info button span {
    font-size: 16px;
  }

  .product-description-details .product-detail,
  .product-description-details .product-desc {
    font-size: 14px;
  }
}
