.contact-us .contact-banner {
    background-color: #00afef;
}

.contact-us .contact-info {
    display: flex;
    gap: 16px;
}

.contact-us .contact-info div.img {
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #00afef7c;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-us .contact-info div.img svg {
    width: 20px;
    height: 20px;
}

.contact-us .contact-info .text-div {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.contact-us .contact-info .text-div .font-light {
    line-height: normal;
}

.comment-form input,
.comment-form textarea {
    height: 22px;
    width: 330px;
    outline: none;
    padding: 10px;
    box-sizing: content-box;
    border: .1mm solid #00000036;
}

.comment-form textarea {
    height: 140px;
    width: 94%;
}

.comment-form button {
    background-color: #00afefa8;
}

@media screen and (max-width: 1024px) {
    .contact-us .container.max-w-screen-xl {
        justify-content: space-evenly;
    }

    .comment-form input {
        width: auto;
    }
}

@media screen and (max-width: 768px) {
    .contact-us .container.max-w-screen-xl {
        padding: 0 30px;
    }

    .comment-form input {
        width: 318px;
        max-width: -webkit-fill-available;
    }

    .container.max-w-screen-xl.mx-auto.flex.flex-col-reverse>div {
        padding: 0;
        margin-top: 30px;
    }
}