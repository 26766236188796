.mission-banner {
    background-image: url('../../assets/images/happy-volunteer-coordinator.jpg');
    height: 500px;
    background-size: cover;
    /* background-color: #00afef;
    background-position-y: -130px; */
    background-repeat: no-repeat;
}

.bar-image{
    background-image: url('../../assets/images/image-bar.jpg');
    height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
}

.mission-banner::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.75;
    background: #07bbfd;
}

.goals-div .heading {
    color: #ffffff;
    font-weight: 700;
}

.goals-div .heading * {
    line-height: normal;
}

.goals-div .heading h4 {
    font-size: 50px;
}

.goals-div .heading h5 {
    font-size: 35px;
}

.goals-div .heading h1 {
    font-size: 95px;
    font-weight: 900;
}

.goals-items .text-div {
    color: #ffffff;
}

.goals-items .text-div p.heading {
    text-transform: uppercase;
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
}

.goals-items .text-div p.desc {
    font-weight: 400;
    padding: 0 20px;
    line-height: normal;
    margin-top: 12px;
}

.goals-items .text-div button {
    width: 200px;
    height: 50px;
    margin-top: 20px;
    border-radius: 6px;
    background-color: #ffffff;
}

.goals-items .text-div button span {
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 47px;
    color: #000000;
    font-weight: 300;
}

.goals-items .goal-item {
    height: 512px;
}

.donation .logo-div img {
    width: 150px;
}

@media screen and (max-width: 1024px) {
    .mission-banner {
        height: 450px;
        background-position-y: -20px;
    }
    .goals-div .heading.flex {
        zoom: 60%;
    }

    .goals-div .goals-items {
        zoom: 80%;
    }
}

@media screen and (max-width: 768px) {
    .mission-banner {
        background-position-y: 0px;
        background-position: center;
    }
    
    .goals-div .heading.flex {
        zoom: 50%;
    }

    .goals-div .goals-items {
        zoom: 60%;
    }

    .donation .logo-div img {
        width: 100px;
    }
}

@media screen and (max-width: 550px) {
    .mission-banner {
        height: 325px;
    }
    
    .goals-div .heading.flex {
        zoom: 40%;
    }

    .goals-div .goals-items {
        zoom: 80%;
    }

    .donation .logo-div {
        width: 100% !important;
        max-width: max-content;
        overflow: auto;
        justify-content: start;
        padding: 0 10px;
    }

    .goals-div .heading h1 {
        font-size: 100px;
    }
}

@media screen and (max-width: 380px) {
    .goals-div .heading h4 {
        font-size: 42px;
    }
    
    .goals-div .heading img {
        display: none;
    }
    
    .donation .logo-div img {
        width: 75px;
    }
}