.banner {
  background-image: url("../../assets/images/home-banner.png");
  background-size: cover;
  min-height: 874px;
  position: relative;
  background-position: left;
  background-repeat: no-repeat;
}

.home-banner-carousel-item .text-div,
.home-banner-carousel-item .image-div {
  position: relative;
}

.home-banner-carousel-item .text-div::before,
.home-banner-carousel-item .image-div::after,
.our-mission .txt-div::after {
  content: "";
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.home-banner-carousel-item .text-div::before {
  /* background-image: url('../../assets/svg/drop-logo.svg'); */
  left: 5px;
  top: 30px;
  bottom: 0;
  opacity: 0.46;
}

.home-banner-carousel-item .image-div::after {
  /* background-image: url('../../assets/svg/ice-1.svg'); */
  right: 30px;
  top: 90px;
  max-width: 1020px;
  max-height: 709px;
  opacity: 0.8;
}

/* .banner .banner-img img {
    width: 620px;
    height: auto;
} */

.banner h1 {
  font-size: 85px;
  color: #ffffff;
  line-height: 6.6rem;
  font-weight: 900;
}

.banner p {
  font-size: 22px;
  line-height: 35px;
  color: #363636;
  font-weight: 500;
  padding-left: 4px;
}

.banner button.learn-more {
  width: 173px;
  height: 49px;
  border-radius: 6px;
  color: white;
  outline: none;
}

.home-banner-carousel .slick-dots {
  /* bottom: -155px; */
}

.home-banner-carousel .slick-arrows svg path {
  fill: black !important;
}

.home-banner-carousel .slick-arrow.slick-prev {
  left: -75px;
}

.home-banner-carousel .slick-arrow.slick-next {
  right: -55px;
}

.quick-easy {
  height: auto;
  margin-top: -4.5rem;
}

.quick-easy .heading span:first-child {
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 35px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 500;
}

.quick-easy .heading span:last-child {
  font-size: 52px;
  color: #ffffff;
  font-weight: 700;
}

.quick-easy .cards-div .card {
  width: 310px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 22px;
  color: #ffffff;
}

.quick-easy .cards-div .card span {
  /* margin-bottom: 45px; */
  /* height: 128px; */
  display: flex;
}

.quick-easy div.cards-div > div > p:nth-child(2) {
  font-size: 21px;
  font-weight: 500;
  text-transform: uppercase;
}

.quick-easy .cards-div .card p:nth-child(3) {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 18px;
}

.bottles {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.bottles .slick-track {
  display: flex !important;
  align-items: flex-end;
}

.bottles .bottle-card {
  display: inline-flex !important;
}

.bottles .slick-dots {
  bottom: -45px;
}

.bottles .bottle-card p {
  line-height: 24px;
  color: #000000;
  font-weight: 500;
  text-align: center;
}

.bottles .bottle-card p:nth-child(2) {
  font-size: 20px;
  text-transform: uppercase;
}

.bottles .bottle-card p:nth-child(3) {
  font-size: 16px;
}

.bottles .bottle-card a,
.bottles .bottle-card button {
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 55px;
  text-transform: uppercase;
  color: #000000;
  font-weight: 300;
}

.bottles .bottle-card {
  gap: 10px;
}

.bottles .bottle-card img {
  width: 100%;
  height: 100%;
}

.bottles .bottle-card .bottle-img-200-ml {
  width: auto;
  height: 361px;
}

.bottles .bottle-card .bottle-img-330-ml {
  width: auto;
  height: 410px;
}

.bottles .bottle-card .bottle-img-500-ml {
  width: auto;
  height: 425px;
}

.bottles .bottle-card .bottle-img-1-5-ml {
  width: auto;
  height: 450px;
}

.bottles .bottle-card .bottle-img-5-Gallon {
  width: auto;
  height: 490px;
}

.bottles .bottle-card a,
.bottles .bottle-card button {
  width: 153px;
  height: 39px;
  border-radius: 6px;
  display: flex;
  background-color: #ffffff;
  border: 1px solid #000000;
  justify-content: center;
  align-items: center;
}

.bottles .bottle-card a span,
.bottles .bottle-card button span {
  font-size: 14px;
  letter-spacing: 1px;
  /* line-height: 55px; */
  text-transform: uppercase;
  color: #000000;
  font-weight: 300;
}

.our-mission .div-1,
.our-mission .div-2 {
  height: 552px;
  background-color: #d5eaf9;
}

.our-mission .div-2 {
  background-color: #30a0d9;
}

.our-mission .flex .txt-div {
  position: relative;
  text-transform: uppercase;
  color: #000000;
  width: 580px;
}

.our-mission .div-1 .txt-div::after,
.our-mission .div-2 .txt-div::after {
  background-image: url("../../assets/svg/drop-logo.svg");
  background-image: url("../../assets/svg/drop-logo.svg");
  background-position: center left;
  background-size: cover;
  width: 500px;
  right: -505px;
  top: 0;
  max-height: 709px;
  opacity: 0.2;
}

.our-mission .div-2 .txt-div::after {
  right: -200px;
}

.our-mission .flex .txt-div p {
  position: relative;
  font-size: 15px;
  letter-spacing: 1px;
  line-height: 47px;
  font-weight: 500;
  width: fit-content;
}

.our-mission .flex .txt-div p::after {
  position: absolute;
  content: "";
  width: 70px;
  border: 0.2px solid black;
  top: 50%;
  opacity: 0.4;
}

.text-white-line {
  position: absolute;
  content: "";
  width: 70px;
  border: 0.2px solid white;
  opacity: 0.4;
}

.our-mission .flex .txt-div h3 {
  font-size: 35px;
  line-height: 42px;
  font-weight: 700;
  margin-bottom: 8px;
}

.our-mission .flex .txt-div span {
  font-size: 18px;
  font-weight: 200;
  text-transform: none;
  color: #000000;
}

.our-mission .flex .txt-div button {
  width: 173px;
  height: 49px;
  border-radius: 6px;
  background-color: #000000;
  margin-top: 15px;
}

.our-mission .flex .txt-div button span {
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 47px;
  color: #ffffff;
  font-weight: 500;
}

.uaques-basic-water {
  background-image: url("../../assets/images/wave-top.png");
  background-size: 110%;
  background-repeat: no-repeat;
  background-position-y: 40px;
  background-position-x: -50px;
  position: relative;
  margin: 80px 0;
}

.uaques-basic-water::after {
  position: absolute;
  content: "";
  display: block;
  left: 0;
  right: 0;
  z-index: -1;
  height: 260px;
  top: 180px;
  background-color: #f3f9ff;
}

.uaques-basic-water::before {
  position: absolute;
  content: "100% Purified Water";
  display: block;
  top: 350px;
  left: 0px;
  opacity: 0.102;
  font-size: 80px;
  text-transform: uppercase;
  color: #000000;
  font-weight: 700;
  text-align: center;
  overflow: auto;
  z-index: 1;
  width: 100%;
}

.uaques-basic-water .rings {
  width: 168px;
  height: 168px;
  border-radius: 50%;
  background-color: #00afef;
  position: absolute;
  left: 30%;
}

.uaques-basic-water .rings::before,
.uaques-basic-water .rings::after {
  position: absolute;
  content: "";
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 1px solid #00afef;
}

.uaques-basic-water .rings::before {
  top: -13px;
  left: -13px;
}

.uaques-basic-water .rings::after {
  top: 18px;
  left: 18px;
}

.uaques-basic-water .bottles {
  width: 374px;
  height: 374px;
  border-radius: 50%;
  background-color: #00afef;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.uaques-basic-water .bottles img {
  min-width: 460px !important;
  margin-top: 40px;
}

.uaques-basic-water .ice-cubes {
  position: absolute;
  width: 470px;
  position: absolute;
  right: 290px;
  width: 470px;
  top: 125px;
}

.uaques-basic-water .composition-div-outer {
  padding: 40px 0;
  margin-top: 50px;
  margin-bottom: 150px;
  background-color: #f3f9ff;
  position: relative;
}

.uaques-basic-water .composition-div-outer .heading {
  font-size: 45px;
  color: #000000;
  font-weight: 700;
  text-align: center;
  line-height: normal;
  width: 420px;
}

.uaques-basic-water .composition-div .composition-txt {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 20px;
  flex: 1;
}

.uaques-basic-water .composition-div .composition-txt p {
  width: 270px;
  height: 50px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.uaques-basic-water .composition-div .composition-txt.div-1 {
  text-align: right;
}

.uaques-basic-water .composition-div .composition-txt .compo-elemnt h3 {
  line-height: normal;
  margin-bottom: 10px;
}

.uaques-basic-water .composition-div .composition-txt h3 {
  font-size: 18px;
  text-transform: uppercase;
  color: #000000;
  font-weight: 700;
}

.uaques-basic-water .compo-last-div h1 {
  position: relative;
  flex: 1;
}

.uaques-basic-water .compo-last-div h1:not(:last-child)::after {
  content: "";
  background-image: url("../../assets/images/curly-border.png");
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: -10px;
  right: 0;
  height: 100%;
  width: 30px;
}

.composition-div-outer::after {
  content: "";
  background-image: url("../../assets/images/wave-top.png");
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  bottom: -140px;
  height: 150px;
  left: 0;
  right: 0;
  transform: rotate(180deg);
}

.insta-carousel {
  width: 100%;
  margin: 20px auto;
  overflow: hidden;
}

.insta-carousel .slick-slide {
  display: flex;
  justify-content: center;
}

.insta-carousel .img-card {
  position: relative;
  min-width: 200px;
  max-width: 300px; /* Adjust as needed */
  max-height: 275px;
  background-color: rgb(241, 241, 243);
  margin: 0 10px; /* Adjust spacing between slides as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.insta-carousel .img-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.insta-carousel .txt-div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: none;
  color: white;
  font-size: 16px;
  text-align: justify;
  line-height: normal;
  transition: all 0.3s ease;
}

/* Uncomment if you want to show text on hover */
/* .insta-carousel .img-card:hover .txt-div {
    display: block;
  } */

@media screen and (min-width: 1930px) {
  .home-banner-carousel-item::after {
    display: none;
  }

  .composition-div-outer::after {
    background-size: cover;
  }
}

@media screen and (max-width: 1440px) {
  .banner {
    min-height: 745px;
  }

  /* .home-banner-carousel .slick-dots {
        bottom: -75px;
    } */

  .home-banner-carousel-item .image-div::after {
    display: none;
  }

  .banner h1 {
    font-size: 70px;
    line-height: normal;
  }

  /* .banner .banner-img img {
        max-width: 80%;
        width: auto;
    } */

  .uaques-basic-water {
    background-position-y: 70px;
  }

  .uaques-basic-water .ice-cubes {
    right: 130px;
  }

  .uaques-basic-water .rings {
    left: 25%;
  }

  .our-mission .flex .txt-div {
    width: 520px;
  }

  .our-mission .flex .txt-div h3 {
    font-size: 32px;
    line-height: 42px;
  }

  .our-mission .flex .txt-div span {
    font-size: 16px;
    font-weight: 200;
  }

  .home-banner-carousel .slick-arrow.slick-prev {
    left: -65px;
  }

  .home-banner-carousel .slick-arrow.slick-next {
    right: -50px;
  }
}

@media screen and (max-width: 1024px) {
  .banner {
    min-height: 475px;
  }

  .banner h1 {
    font-size: 50px;
  }

  .banner p {
    font-size: 18px;
  }

  .uaques-basic-water {
    background-position-y: 110px;
  }

  .insta-carousel {
    justify-content: start !important;
  }

  .insta-carousel .img-card {
    flex: auto;
    height: auto;
    min-width: min-content;
    max-width: none !important;
    max-height: fit-content;
  }

  .our-mission .flex .txt-div {
    width: 450px;
  }

  .our-mission .flex .txt-div h3 {
    font-size: 28px;
    line-height: 38px;
  }

  .our-mission .flex .txt-div span {
    font-size: 14px;
    font-weight: 200;
  }

  .home-banner-carousel .slick-arrow.slick-prev {
    left: -40px;
  }

  .home-banner-carousel .slick-arrow.slick-next {
    right: -40px;
  }

  /* .home-banner-carousel .slick-dots {
        bottom: -95px;
    } */
}

@media screen and (max-width: 768px) {
  .banner {
    min-height: 1000px;
  }

  .uaques-basic-water::before {
    top: 365px;
    font-size: 60px;
  }

  /* .home-banner-carousel .slick-dots {
        bottom: -155px;
    } */

  .home-banner-carousel .slick-arrow.slick-prev {
    left: 5px;
  }

  .home-banner-carousel .slick-arrow.slick-next {
    right: 5px;
  }

  .quick-easy {
    zoom: 80%;
    padding-bottom: initial;
  }

  .bottle-card {
    zoom: 50%;
  }

  .our-mission .txt-div {
    zoom: 75%;
  }

  .uaques-basic-water .rings {
    left: 5%;
  }

  .uaques-basic-water .ice-cubes {
    right: 280px;
    width: 280px;
    right: 0;
  }

  .uaques-basic-water .composition-div-outer {
    padding: 20px 10px;
    margin-bottom: 100px;
  }

  .uaques-basic-water .composition-div-outer .heading {
    font-size: x-large;
    margin-bottom: 15px;
  }

  .composition-txt {
    zoom: 65%;
  }

  .uaques-basic-water .compo-last-div h1 {
    font-size: small;
    font-weight: 400;
  }

  .uaques-basic-water .compo-last-div h1::after {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .home-banner-carousel-item {
    margin-top: -5px;
  }
  .banner {
    min-height: 760px;
    /* zoom: 50%; */
  }

  .banner p {
    font-size: 16px;
    line-height: 26px;
  }

  /* .home-banner-carousel .slick-dots {
        bottom: -115px;
    } */

  .quick-easy .heading span:last-child {
    font-size: 38px;
  }

  .banner h1,
  .banner p {
    text-align: center;
  }
  .banner h1 {
    font-size: 40px;
  }
  .banner button.learn-more {
    margin-top: 880px;
    position: absolute;
    z-index: 11;
  }
  .banner {
    /* min-height: 570px; */
    min-height: 650px;
    /* zoom: 50%; */
  }
  .home-banner-carousel-item .image-div {
    margin-top: -39px;
  }
  .carousel-item {
    height: 580px;
  }
  .home-banner-carousel {
    height: 600px;
  }
  /* .quick-easy {
        zoom: 50%;
    } */

  .composition-txt {
    zoom: 55%;
  }

  .uaques-basic-water .bottles {
    width: 225px;
    height: 225px;
  }

  .uaques-basic-water .ice-cubes {
    width: 200px;
  }

  .uaques-basic-water .rings {
    width: 130px;
    height: 130px;
    background: linear-gradient(105deg, #00afef 40%, #00afef3d);
  }

  .uaques-basic-water .rings p {
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
  }

  .uaques-basic-water .composition-div-outer {
    margin-top: 10px;
  }

  .uaques-basic-water .composition-div-outer .heading {
    font-size: large;
  }

  .uaques-basic-water .compo-last-div h1 {
    font-size: 10px !important;
  }

  .uaques-basic-water::before {
    top: 40%;
    font-size: 35px;
  }

  .insta-carousel {
    /* height: 200px; */
  }

  .insta-carousel .img-card {
    min-width: min-content;
  }

  .uaques-basic-water {
    background-position-x: 0;
    background-position-y: 155px;
  }

  .our-mission .flex .txt-div {
    width: 95%;
    zoom: 90%;
  }

  .our-mission .div-1,
  .our-mission .div-2 {
    height: auto;
  }

  .our-mission .flex .txt-div span {
    font-size: 16px;
    line-height: normal;
    font-weight: 300;
  }

  .uaques-basic-water .rings::before,
  .uaques-basic-water .rings::after {
    width: 130px;
    height: 130px;
  }

  .uaques-basic-water .composition-div {
    padding: 8px 10px;
  }

  .uaques-basic-water .composition-div .composition-txt p {
    display: none;
  }
  .quick-easy .cards-div .card span {
    height: 80px;
  }
}

.custom-list {
  list-style-type: disc;
  padding-left: 20px;
}
.no-min-orders {
  margin-top: 1rem; /* Adjust the value as needed */
}

@media screen and (max-width: 550px) {
  .banner button.learn-more {
    margin-top: 800px;
    position: absolute;
    z-index: 11;
  }
}
@media screen and (max-width: 390px) {
  .home-banner-carousel-item {
    margin-top: -10px;
  }
  .uaques-basic-water::before {
    top: 44%;
    font-size: 28px;
  }
  .banner h1 {
    font-size: 32px;
  }
  .banner {
    /* min-height: 570px; */
    min-height: 600px;
    /* zoom: 50%; */
  }
  .banner button.learn-more {
    margin-top: 712px;
    position: absolute;
    z-index: 11;
  }
  .home-banner-carousel {
    height: 525px;
  }
  .home-banner-carousel-item .image-div {
    margin-top: -39px;
  }
  .carousel-item {
    height: 520px;
  }

  .quick-easy .cards-div .card span {
    height: 70px;
  }
}

@media (max-width: 767px) {
  .flex-wrap-on-small {
    flex-wrap: wrap;
  }
}
