header .add-banner-text {
    font-size: 15px;
    letter-spacing: 2px;
    color: #ecf2f6;
    font-weight: 300;
}

header .nav-bar {
    height: 78px;
    background-color: #ffffff;
}

header ul.desktop-nav {
    gap: 24px;
}

header ul li.nav-link {
    font-size: 18px;
    color: #363535;
    font-weight: 400;
    border-bottom: .1mm solid transparent;
}

header ul li.nav-link.active,
header ul li.nav-link:hover {
    color: #1e1d1d;
    border-bottom-color: #1e1d1d;
}

.mobile-menu {
    background-color: #00afef;
    z-index: 1000;
}

.mobile-menu .nav-link a {
    font-size: 34px;
    line-height: 105px;
    color: #ffffff;
    font-weight: 600;
}

@media screen and (max-width: 1024px) {
    header ul.desktop-nav {
        gap: 6px;
    }

    header ul li.nav-link {
        font-size: 14px;
    }
}

@media screen and (max-width: 550px) {
    header .add-banner-text {
        zoom: 80%;
    }
}