/* header .nav-bar {
    padding-right: 100px;
} */

.media-banner .media-banner-inner::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #00afef;
    opacity: 0.65;
}

.media-flex-container {
    margin: 80px 0;
}

.media-flex-container p.descp-txt {
    padding-right: inherit;
}

.media-card {
    display: flex;
    flex-direction: column;
    gap: 14px;
    color: #000000;
}

.media-card .img-div {
    max-width: 100%;
    width: 501px;
    height: 322px;
    border-radius: 10px;
    background-color: #eef7fd;
}

.media-card .heading-txt {
    font-size: 35px;
    line-height: initial;
    text-transform: uppercase;
    font-weight: 700;
}

.image-shadow {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.media-detail .slick-dots {
    display: none !important;
}

.media-detail .slick-prev,
.media-detail .slick-next {
    top: 45%;
}

.media-detail .slick-arrow.slick-next {
    right: -35px;
}

.media-detail .slick-arrow.slick-prev {
    left: -35px;
}

.photo-video-div button {
    color: #00afef;
    background-color: white;
}

.photo-video-div button.active {
    background-color: #00afef;
    color: white;
}

.photo-video-div button.active::after {
    content: '';
    position: absolute;
    right: -6px;
    top: 35%;
    border-radius: 2px;
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    background-color: #00afef;
}

.media-detail .description p {
    text-align-last: center;
}

@media screen and (max-width: 1440px) {
    .media-card .img-div {
        max-width: -webkit-fill-available;
    }
}


@media screen and (max-width: 1024px) {
    .media-flex-container .media-card {
        text-align: center;
        zoom: 70%;
    }
}


@media screen and (max-width: 550px) {
    .media-card .heading-txt {
        font-size: 28px;
    }

    div.media-flex-container>div>div.flex.flex-wrap {
        row-gap: 10px !important;
    }
}