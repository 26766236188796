.modal-box {
  width: 60%;
  max-height: 80vh; /* Allow scrolling if content overflows */
  overflow-y: auto;
  background-color: #ffffff;
  border: 2px solid #000000;
  box-shadow: 24px 24px 24px rgba(0, 0, 0, 0.1);
  padding: 32px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
}

.modal-box h2 {
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 16px;
}

.modal-box p,
.modal-box .heading {
  font-size: 18px;
  line-height: 30px;
  color: #000000;
  font-weight: 300;
}

.modal-box .heading {
  color: #00afef;
  font-weight: bold;
  margin-bottom: 8px;
}

@media screen and (max-width: 768px) {
  .modal-box {
    width: 90%;
  }

  .modal-box p {
    line-height: 28px;
  }
}
