.accessibility .contact-banner {
    background-color: #00afef;
}

.accessibility .container {
    max-width: 1102px;
    width: 80%;
    border: .1mm solid #00afef;
    border-radius: 8px;
}

.accessibility .container p,
.accessibility .container span.heading {
    font-size: 18px;
    line-height: 35px;
    color: #000000;
    font-weight: 300;
}

.accessibility .container span.heading {
    color: #00afef;
    line-height: 25px;
}

@media screen and (max-width: 768px) {
    .accessibility .container {
        width: 90%;
        text-align: justify;
    }

    .accessibility .container p {
        line-height: 28px;
    }
}