/* Google fonts */
/* 1. Outfit */
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

*,
html,
body {
    font-family: "Outfit", sans-serif;
}

::-webkit-scrollbar {
    width: 0;
    height: 0;
}

/* Optional: Hide scrollbar track */
::-webkit-scrollbar-track {
    background: transparent;
}

#root {
    overflow: hidden;
}