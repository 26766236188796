.faqs .faq-banner {
    background-color: #00afef;
}

.question-answers .question,
.text-heading {
    color: #00afef;
}

.question-answers .text-heading,
.text-heading.heading-top {
    line-height: 0.65;
}

.question-answers .faqas-outer {
    background-color: #eef7fd;
    border-radius: 6px;
}

.question-answers .faqas-outer p.answer {
    font-size: 18px;
    line-height: 35px;
    color: #000000;
    font-weight: 300;
}