.checkout {
  z-index: 120;
}

.checkout-items-outer {
  height: 100vh;
}

.close-btn {
  display: flex;
  justify-content: flex-end;
}
