.footer {
    height: auto;
    color: white;
    background-color: #0f2d51;
}

.footer .footer-subscribe {
    height: 180px;
    display: flex;
    align-items: center;
}

.footer .footer-subscribe input[type='text'] {
    width: 500px;
    height: 60px;
    color: black;
    font-size: large;
    outline: none;
    padding: 6px 12px 6px 16px;
    background-color: #ffffff;
    border-top-left-radius: 41px;
    border-bottom-left-radius: 41px;
}

.footer .footer-subscribe .subscribe-btn {
    height: 60px;
    width: 150px;
    font-size: large;
    font-weight: 700;
    border-top-right-radius: 41px;
    border-bottom-right-radius: 41px;
    background: radial-gradient(circle, rgba(162, 192, 228, 1) 0%, rgba(79, 126, 182, 1) 100%);
}

.footer .logo-div p {
    width: 420px;
    height: 112px;
    font-size: 14.5px;
    line-height: 24px;
    margin: 0;
    color: #ffffff;
    font-weight: 300;
}

.footer .contact-div {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
}

.footer .contact-div span.logo {
    width: 21px;
    height: 21px;
    border-radius: 50%;
}

.footer .links ul {
    gap: 6px;
    /* display: flex; */
    flex-direction: column;
    font-weight: 600;
    font-size: 14px;
    padding-top: 10px;
}

.footer-description {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1024px) {

    .footer .footer-subscribe input[type='text'] {
        width: auto;
    }

    .footer .footer-subscribe div.w-full:first-child {
        text-align: center;
    }

    .footer .logo-div p {
        width: 300px;
        height: 160px;
    }
}

@media screen and (max-width: 768px) {

    .footer .footer-subscribe button.subscribe-btn {
        width: auto;
    }

    .footer .footer-subscribe button span {
        font-size: small;
        padding: 0 14px;
    }

    .footer .logo-div {
        justify-content: center;
    }

    .footer .contact-info {
        align-items: center;
        gap: 0.5rem;
    }

    .footer .social-links li.nav-link a {
        justify-content: center;
    }

    .footer .links h3,
    .footer .links li {
        text-align: center !important;
    }

    .footer .border-between {
        margin: 0;
    }
}

@media screen and (max-width: 550px) {

    .footer .footer-subscribe button.subscribe-btn {
        width: 120px;
    }

    .footer .container.px-2 {
        padding-bottom: 40px;
    }

    .footer .footer-subscribe {
        text-align: center;
    }

    .footer .logo-div {
        justify-content: start;
    }

    .footer .logo-div p {
        width: 100%;
    }

    .footer .contact-info {
        align-items: start;
    }

    .footer .links h3,
    .footer .links li {
        text-align: start !important;
    }

    .footer .footer-subscribe input[type='text'] {
        width: 100%;
        height: 50px;
    }

    .footer .footer-subscribe .subscribe-btn {
        height: 50px;
    }

    .footer .social-links ul.list-none {
        flex-direction: row;
    }

    .footer-copyright {
        zoom: 80%;
        padding: 0 30px;
    }
}

@media screen and (max-width: 380px) {
    .footer .footer-subscribe {
        height: 250px;
    }
}